@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Form";

#{$selector} {
    &__hp {
        display: none !important;
    }
    
    &__success {
        color: $light--success;
        font-weight: 700;
    }

    &__errors {
        margin-bottom: $space-md;

        &__list {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        &__list__item {
            @include text("meta");
        }
    }
}
