@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".CBSettingsDetail";

#{$selector} {
    &__header {
        display: flex;
        align-items: center;
        padding: $space-lg 0;

        &__icon {
            margin-right: $space-lg;
            cursor: pointer;
        }
    }

    &__accordion__meta {
        @include text("meta");
        color: $light--base;

        &--dark {
            color: $dark--base;
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;

        &__item {
            $bgOpacity: 0.1;
            padding: $space-md;
            margin: $space-sm 0;
            border-radius: $ui-default-border-radius;
            background-color: rgba($light--base, $bgOpacity);

            &--primary {
                background-color: rgba($light--primary, $bgOpacity);
            }

            &--secondary {
                background-color: rgba($light--secondary, $bgOpacity);
            }

            &--teritary {
                background-color: rgba($light--teritary, $bgOpacity);
            }

            &--success {
                background-color: rgba($light--success, $bgOpacity);
            }

            &--error {
                background-color: rgba($light--error, $bgOpacity);
            }

            &--info {
                background-color: rgba($light--gray, $bgOpacity);
            }

            &--warning {
                background-color: rgba($light--warning, $bgOpacity);
            }

            &--white {
                background-color: none;
            }

            &--dark {
                background-color: rgba($dark--base, $bgOpacity);

                &--primary {
                    background-color: rgba($dark--primary, $bgOpacity);
                }
    
                &--secondary {
                    background-color: rgba($dark--secondary, $bgOpacity);
                }
    
                &--teritary {
                    background-color: rgba($dark--teritary, $bgOpacity);
                }
    
                &--success {
                    background-color: rgba($dark--success, $bgOpacity);
                }
    
                &--error {
                    background-color: rgba($dark--error, $bgOpacity);
                }
    
                &--info {
                    background-color: rgba($dark--gray, $bgOpacity);
                }
    
                &--warning {
                    background-color: rgba($dark--warning, $bgOpacity);
                }
    
                &--white {
                    background-color: none;
                }
            }
        }

        &__item__content {
            @include text("meta");
            display: flex;
            justify-content: space-between;
        }
    }
}
