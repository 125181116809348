@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".CBSettings";

#{$selector} {
    &__header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__logo {
            position: relative;
            display: block;
            width: 200px;
            height: 35px;
        }
    }

    &__button {
        margin-bottom: $space-lg !important;
    }

    &__headline {
        padding-bottom: $space-lg;
    }

    &__paragraph {
        @include text("meta");
    }

    &__accordion {
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 50px;
            width: 100%;
            padding-right: $space-lg;
        }

        &__link {
            @include text("link");
            display: table;
            margin-top: $space-md;
            cursor: pointer;

            @include when-hovered {
                color: $light--primary;
                text-decoration: underline;
            }
        }
    }

    &__footer {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: $space-lg $space-lg 0 $space-lg;
        display: flex;
        justify-content: space-between;
        background-color: $light--backgroundWhite;
        border-top: 1px solid rgba($light--lightGray, 0.25);

        &--dark {
            background-color: $dark--backgroundWhite;
        }
    }
}
