@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Footer";

#{$selector} {
    width: 100%;
    background: $light--backgroundWhite;
    border-radius: $ui-background-border-radius $ui-background-border-radius 0 0;
    background: $light--background;

    &--dark {
        background: $dark--backgroundWhite;
    }

    &__content {
        padding: $space-2xl $space-xl $space-xl $space-xl;
    }

    &__col {
        width: 100%;
    }

    &__logo {
        position: relative;
        display: block;
        width: 150px;
        height: 22px;
        margin-bottom: $space-md;

        &__link {
            @include text('meta');
            margin-right: $space-xs;
            padding-right: $space-xs;
            border-right: 1px solid $light--lightGray;
    
            &--dark {
                color: $dark--lightGray;
                border-right-color: $dark--lightGray;
            }
    
            &:last-child {
                margin-right: 0;
                padding-right: 0;
                border: none;
            }
        }
    }

    &__headline {
        margin-bottom: $space-lg;
    }

    &__desc {
        @include text('meta');
        margin: 0;
        color: $light--base;

        &--dark {
            color: $dark--base;
        }
    }

    &__copyright {
        @include text('meta');
        display: block;
        margin-top: $space-lg;

        &--dark {
            color: $dark--lightGray;
        }
    }

    &__nav {
        display: grid;
        grid-template-columns: auto;
        margin: 0;
        padding-left: 0;
        list-style-type: none;

        &__item {
            margin: $space-md 0;
        }

        &__link {
            @include text('meta');
            text-decoration: none !important;
        }

        @include screenLargerThan($screen-md) {
            grid-template-columns: auto auto;
        }

        @include screenLargerThan($screen-lg) {
            grid-template-columns: auto auto auto;

            &__item {
                margin: 0;
            }
        }

        @include screenLargerThan($screen-xl) {
            justify-content: space-evenly;

            &__item {
                width: fit-content;
            }
        }
    }

    &__social {
        &__links {
            display: flex;
            flex-direction: row;
            margin: $space-lg 0 0 0;
            padding-left: 0;
            list-style-type: none;

            &__item {
                margin-right: $space-md;
            }
        }
    }
}
