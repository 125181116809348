@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".FooterForm";

#{$selector} {
    display: block;
    padding: ($space-2xl * 3) $space-xl $space-2xl $space-xl;
    border-radius: 0 0 $ui-background-border-radius $ui-background-border-radius;
    background: $light--backgroundWhite;

    &--dark {
        background: $dark--background;
    }

    &__form {
        @include centered-container();

        &__headline {
            padding-bottom: $space-xl;
        }
    }
}