@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Input";

#{$selector} {
  &__helperText__wrapper {
    padding-top: $space-xs;
    margin-bottom: 0 !important;
  }

  &__helperText {
    @include text("base");
    display: flex;

    &--light {
      color: $light--gray;
    }

    &--dark {
      color: $dark--lightGray;
    }

    &__content {
      width: 75%;
      padding-bottom: $space-md;
    }

    &__legend {
      width: 25%;
      text-align: right;
    }
  }
}
