@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Select";

#{$selector} {
    border-radius: 15px;
    box-shadow: $elevation--card;
    background: $light--backgroundWhite;

    &--dark {
        box-shadow: $elevation--card--dark;
        background: $dark--backgroundWhite;
    }

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: $space-md $space-lg !important;
    }
}
