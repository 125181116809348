@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Switch";

#{$selector}

 {
    &__control {
        color: $light--base;

        &--dark {
            color: $dark--base;
        }
    }

    &__icon {
        color: $light--base;

        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: 'border-box';
            width: 22px;
            height: 22px;
            background-color: $white;
            border-radius: 200px;
        }

        &--light {
            &--primary {
                color: $light--primaryLight;
            }

            &--secondary {
                color: $light--teritary;
            }

            &--success {
                color: $light--success;
            }

            &--error {
                color: $light--error;
            }

            &--info {
                color: $light--gray;
            }

            &--warning {
                color: $light--warning;
            }
        }

        &--dark {
            &--primary {
                color: $dark--primaryDark;
            }

            &--secondary {
                color: $dark--teritary;
            }

            &--success {
                color: $dark--success;
            }

            &--error {
                color: $dark--error;
            }

            &--info {
                color: $dark--gray;
            }

            &--warning {
                color: $dark--warning;
            }
        }
    }
}
